.App
  min-height: 100vh
  background: #000

  .text-content
    height: 100vh
    max-width: 700px
    box-sizing: border-box
    padding: 80px
    z-index: 1

    h1
      color: #fff
      font-size: 108px
      line-height: 120px
      margin: 0
    
    p
      color: #fff
      font-size: 32px
      line-height: 1.5
      margin: 0
      padding-top: 20px
    
    footer
      color: #fff
      margin: 0
      padding-top: 80px
      font-size: 24px
      position: absolute
      bottom: 80px
  
  img
    max-height: 100vh
    max-width: 100vw
    position: fixed
    bottom: 0
    right: 0
    z-index: 0

  @media screen and (max-aspect-ratio: 3/2), (max-width: 1880px)
    background: #0093CB
    
    .text-content
      max-width: 540px

      @media screen and (max-width: 540px)
        padding: 28px
      
      h1
        font-size: 62px
        line-height: 70px
      
      p
        font-size: 24px
        padding-top: 14px
      
      footer
        font-size: 18px
    
    img
      opacity: 0.2

@media (prefers-reduced-motion: no-preference)
